::-webkit-scrollbar {
  width: 10px;
  direction: ltr;
}

::-webkit-scrollbar-track {
  background: #ffffff00;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(267deg, #da7c25 0.36%, #b923e1 102.06%);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: #07a002;
}

.my-masonry-grid {
  display: flex;
}

.popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}

.popup::-webkit-scrollbar {
  width: 0;
}

.popup-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: black;
  width: 100%;
}

.popup-image {
  max-width: 100%;
  max-height: 100%;
}

#close_Panel {
  right: 0;
  position: fixed;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 10%;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  gap: 40px;
  font-size: 16px;
}

.nav-connect {
  padding: 15px 20px;
  border-radius: 50px;
  background: linear-gradient(267deg, #da7c25 0.36%, #b923e1 102.06%);
  font-size: 16px;
  cursor: pointer;
  transition: 0.5s;
}

.logo {
  width: 50px;
}

.nav-connect:hover {
  transform: scale(1.05);
}

.nav-menu li {
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}

.nav-menu li img {
  display: flex;
  margin: auto;
}

.anchor-link {
  text-decoration: none;
  color: #fff;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.nav-mob-close {
  display: none;
  cursor: pointer;
}

.nav-mob-open {
  display: none;
  cursor: pointer;
  width: 30px;
}

@media (max-width: 768px) {
  .navbar {
    margin: auto;
    position: fixed;
    z-index: 50;
    padding: 0.3rem;
    display: flex;
    width: 100%;
    align-items: center;
    background: #161513;
  }

  .nav-connect {
    display: none;
  }

  .nav-mob-open {
    display: block;
    position: absolute;
    right: 30px;
  }

  .nav-mob-close {
    display: block;
    position: absolute;
    top: 30px;
    right: 7%;
    width: 30px;
  }

  .navbar ul {
    position: fixed;
    flex-direction: column;
    align-items: start;
    top: 0;
    gap: 30px;
    background-color: #10060d;
    width: 300px;
    height: 100%;
    z-index: 2;
    transition: right 0.5s;
    right: -300px;
    padding-top: 100px;
  }

  .nav-menu li {
    font-size: 22px;
    padding-left: 50px;
    flex-direction: row;
    gap: 20px;
  }
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;
  height: 100vh;
}

.profile-img {
  margin-top: 30px;
  width: 240px;
  border-radius: 50%;
}

.hero h1 {
  text-align: center;
  font-size: 65px;
  font-weight: 600;
  line-height: 70px;
}

.hero h1 span {
  background: linear-gradient(270deg, #df8908 10%, #b415ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero p {
  width: 50%;
  text-align: center;
  font-size: 18px;
  line-height: 30px;
}

.hero-action {
  display: flex;
  align-items: center;
  gap: 25px;
  font-weight: 500;
  margin-bottom: 50px;
}

.hero-connect {
  padding: 20px 30px;
  border-radius: 50px;
  background: linear-gradient(264deg, #df8908 -5.09%, #b415ff 106.28%);
  cursor: pointer;
}

.hero-resume {
  padding: 20px 55px;
  border-radius: 50px;
  border: 2px solid #fff;
  cursor: pointer;
}

.hero-connect:hover {
  border: 2px solid white;
}

.hero-resume:hover {
  border-color: #b415ff;
}

@media (max-width: 768px) {
  .profile-img {
    margin-top: 100px;
    width: 200px;
  }

  .hero h1 {
    margin-top: 10px;
    width: 90%;
    font-size: 35px;
  }

  .hero p {
    width: 80%;
    font-size: 16px;
    line-height: 25px;
    margin: 5px 0;
  }

  .hero-action {
    gap: 10px;
    font-weight: 500;
    margin-bottom: 50px;
  }

  .hero-connect {
    padding: 20px;
    border-radius: 40px;
    font-size: 14px;
  }

  .hero-resume {
    padding: 18px 30px;
    border-radius: 40px;
    font-size: 14px;
  }
}

.about,
.services,
.mywork,
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;
  margin: 70px 10%;
  height: 100vh;
}

.about-sections {
  display: flex;
  gap: 60px;
}

.about-left img {
  border-radius: 10px;
}

.about-right {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.about-para {
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: 18px;
  font-weight: 500;
}

.about-skills {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.about-skill {
  display: flex;
  gap: 20px;
  align-items: center;
  transition: 0.3s;
}

.about-skill p {
  min-width: 130px;
  font-size: 20px;
  font-weight: 500;
}

.about-skill hr {
  outline: none;
  border: none;
  width: 50%;
  height: 8px;
  border-radius: 50px;
  background: linear-gradient(264deg, #df8908 -5.09%, #b415ff 106.28%);
}

.about-achievements {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 50px;
}

.about-achievement {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  transition: 0.5s;
}

.about-achievement h1 {
  font-size: 40px;
  font-weight: 700;
  background: linear-gradient(270deg, #df8908 50.41%, #b415ff 90.25%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-achievement p {
  font-size: 18px;
  font-weight: 500;
}

.about-skill:hover {
  transform: scale(1.05);
  transition: 0.3s;
}

.about-achievement:hover {
  transform: scale(1.12);
  transition: 0.5s;
}

@media (max-width: 768px) {
  .about {
    gap: 50px;
    margin: 50px 6%;
    align-items: start;
    height: auto;
  }

  .about-sections {
    flex-direction: column;
  }

  .about-para {
    line-height: 25px;
  }

  .about-skill p {
    font-size: 16px;
    min-width: 90px;
  }

  .about-skill hr {
    height: 6px;
  }

  .about-achievements {
    justify-content: space-between;
  }

  .about-achievement {
    gap: 10px;
  }

  .about-achievement h1 {
    font-size: 28px;
  }

  .about-achievement p {
    text-align: center;
    font-size: 12px;
  }
}

.services-format {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid white;
  transition: 0.4s;
  cursor: pointer;
}

.services-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin-bottom: 50px;
}

.services-format h3 {
  font-size: 22px;
  font-weight: 600;
}

.services-format h2 {
  font-size: 30px;
  font-weight: 800;
  background: linear-gradient(270deg, #df8908 50%, #b415ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.services-format p {
  color: #d4d4d4;
  font-size: 20px;
  line-height: 30px;
  max-width: 300px;
}

.services-readmore {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 20px;
}

.services-readmore img {
  width: 20px;
}

.services-format:hover {
  border: 2px solid #ff00ff;
  background-image: linear-gradient(45deg, #3f0028, #582300);
  transform: scale(1.05);
  transition: 0.4s;
}

@media (max-width: 768px) {
  .services {
    gap: 70px;
    margin: 20px 6%;
    height: auto;
  }

  .services-format {
    gap: 10px;
    padding: 20px;
  }

  .services-format h2 {
    font-size: 20px;
  }

  .services-format p {
    font-size: 16px;
    line-height: 26px;
    max-width: 300px;
  }

  .services-container {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.mywork-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}

.mywork-container img {
  width: 100%;
  transition: 0.3s;
  cursor: pointer;
}

.mywork-container img:hover {
  transform: scale(1.1);
  border: 4px solid #ff00ff;
  border-radius: 10px;
  transition: 0.3s;
}

.mywork-showmore {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 2px solid white;
  padding: 20px 40px;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  transition: 0.6s;
  cursor: pointer;
}

.mywork-showmore img {
  width: 25px;
}

.mywork-showmore:hover {
  gap: 30px;
  transition: 0.6s;
}

@media (max-width: 768px) {
  .mywork {
    gap: 50px;
    margin: 50px 6%;
    align-items: start;
    height: auto;
  }

  .mywork-container {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .mywork-showmore {
    padding: 15px 30px;
    font-size: 16px;
    margin: 10px auto;
  }

  .mywork-showmore:hover {
    gap: 20px;
  }
}

.contact-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.contact-left {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-basis: 40%;
}

.contact-left h1 {
  font-size: 50px;
  font-weight: 700;
  background: linear-gradient(270deg, #df8908 80%, #b415ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact-left p {
  max-width: 550px;
  color: #d8d8d8;
  font-size: 16px;
  line-height: 25px;
}

.contact-details {
  display: flex;
  flex-direction: column;
  gap: 25px;
  color: #d8d8d8;
  font-size: 22px;
}

.contact-detail {
  display: flex;
  align-items: center;
  gap: 18px;
}

.contact-detail img {
  width: 25px;
}

.contact-right {
  flex-basis: 55%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 15px;
}

.contact-right label {
  color: #d8d8d8;
}

.contact-right input,
.contact-right textarea {
  border: none;
  width: 100%;
  padding: 18px 0 18px 20px;
  border-radius: 4px;
  background: #32323c;
  color: #a0a0a0;
  font-family: Outfit;
  font-size: 18px;
}

.contact-submit {
  border: none;
  color: #fff;
  border-radius: 50px;
  background: linear-gradient(264deg, #df8908 -5.09%, #b415ff 106.28%);
  font-size: 18px;
  padding: 18px 40px;
  margin-top: 15px;
  cursor: pointer;
  transition: 0.3s;
}

.contact-submit:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

@media (max-width: 768px) {
  .contact {
    gap: 40px;
    margin: 50px 6%;
    align-items: start;
    height: auto;
  }

  .contact-section {
    flex-direction: column;
  }

  .contact-left h1 {
    font-size: 35px;
  }

  .contact-detail {
    gap: 12px;
  }

  .contact-detail img {
    width: 20px;
  }

  .contact-left {
    margin-bottom: 30px;
  }

  .contact-right label {
    font-size: 14px;
  }
}

.footer {
  margin: 50px 10%;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.footer-top {
  display: flex;
  justify-content: space-between;
}

.footer-top-left p {
  font-size: 16px;
  max-width: 450px;
}

.footer-top-right {
  display: flex;
  align-items: center;
  gap: 15px;
}

.footer-email-input {
  display: flex;
  gap: 20px;
  padding: 15px 20px;
  border-radius: 50px;
  background: #32323b;
}

.footer-email-input input {
  outline: none;
  border: none;
  background: transparent;
  color: #a0a0a0;
  font-family: Outfit;
  font-size: 18px;
}

.footer-email-input img {
  width: 22px;
}

.footer-subscribe {
  font-size: 16px;
  padding: 18px 40px;
  border-radius: 50px;
  background: linear-gradient(264deg, #df8908 -5.09%, #b415ff 106.28%);
  cursor: pointer;
  transition: 0.3s;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}

.footer-bottom-right {
  display: flex;
  gap: 30px;
  margin-bottom: 10px;
}

.footer-email-input:hover {
  border: 2px solid white;
}

.footer-subscribe:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

@media (max-width: 768px) {
  .footer {
    margin: 50px 6%;
  }

  .footer-top {
    flex-direction: column;
    gap: 30px;
  }

  .footer-top-right {
    gap: 5px;
  }

  .footer-email-input {
    padding: 12px 14px;
    max-width: 200px;
  }

  .footer-email-input input {
    width: 100%;
    font-size: 16px;
  }

  .footer-email-input img {
    display: none;
  }

  .footer-subscribe {
    font-size: 14px;
    padding: 14px 20px;
  }

  .footer-bottom {
    display: flex;
    flex-direction: column-reverse;
    font-size: 14px;
  }

  .footer-bottom-right {
    gap: 12px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  background: #161513;
  color: #fff;
  font-family: outfit;
}

.title-box {
  position: relative;
}

.title-box h1 {
  padding: 0 30px;
  font-size: 60px;
  font-weight: 600;
}

.title-box img {
  width: 160px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}

@media (max-width: 768px) {
  .about-title img {
    width: 130px;
    right: -20px;
  }

  .title-box h1 {
    padding: 0;
    font-size: 40px;
  }

  .title-box img {
    width: 100px;
    right: -40px;
  }
}
